import { FormEventHandler, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../store/redux/userRedux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [noCred, setNoCred] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (email && password) {
      setNoCred(false);
    } else {
      setNoCred(true);
    }
  }, [email, password]);

  const login: FormEventHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const { data } = await axios.post(
        "https://app.nelzmgt.com/api/auth/login",
        { username: email, password }
      );

      setLoading(false);
      dispatch(loginUser(data));
      localStorage.setItem("nelzadmin", data.token);
      navigate("/dashboard");
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="nk-body bg-white npc-default pg-auth">
      <div className="nk-app-root">
        <div className="nk-main ">
          <div className="nk-wrap nk-wrap-nosidebar">
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                <div className="card">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title">Admin</h4>
                        <div className="nk-block-des">
                          <p>
                            Access the admin panel using your email and
                            password.
                          </p>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={login}>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            Username
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="default-01"
                            placeholder="Enter your username"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Password
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            className="form-control form-control-lg"
                            id="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        {loading ? (
                          <button
                            className="btn btn-lg btn-primary btn-block"
                            disabled
                          >
                            Sign in
                          </button>
                        ) : (
                          <button
                            className="btn btn-lg btn-primary btn-block"
                            disabled={noCred}
                            type="submit"
                          >
                            Sign in
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        theme="dark"
        autoClose={3000}
        closeButton={false}
      />
    </div>
  );
};

export default Login;
