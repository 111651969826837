import { useContext } from "react";
import { Link } from "react-router-dom";
import SideBarContext from "../store/context/sidebar-context";

const Sidebar = () => {
  const { sideBarOpen, closeSidebar } = useContext(SideBarContext);

  return (
    <>
      <div
        className={`nk-sidebar nk-sidebar-fixed is-light ${
          sideBarOpen ? "nk-sidebar-active" : ""
        }`}
      >
        <div className="nk-sidebar-element nk-sidebar-head">
          <div className="nk-menu-trigger me-n2">
            <a
              href="#/"
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
              onClick={closeSidebar}
            >
              <em className="icon ni ni-arrow-left" />
            </a>
          </div>
        </div>
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu">
              <ul className="nk-menu">
                <li className="nk-menu-item">
                  <Link
                    onClick={closeSidebar}
                    to="/dashboard"
                    className="nk-menu-link"
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-dashboard-fill" />
                    </span>
                    <span className="nk-menu-text">Dashboard</span>
                  </Link>
                </li>
                <li className="nk-menu-item">
                  <Link
                    onClick={closeSidebar}
                    to="/consultations"
                    className="nk-menu-link"
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-bag-fill" />
                    </span>
                    <span className="nk-menu-text">Consultations</span>
                  </Link>
                </li>
                <li className="nk-menu-item">
                  <Link
                    onClick={closeSidebar}
                    to="/mailing-list"
                    className="nk-menu-link"
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-users-fill" />
                    </span>
                    <span className="nk-menu-text">Mailing List</span>
                  </Link>
                </li>
                <li className="nk-menu-item">
                  <Link
                    onClick={closeSidebar}
                    to="/messages"
                    className="nk-menu-link"
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-chat-fill" />
                    </span>
                    <span className="nk-menu-text">Messages</span>
                  </Link>
                </li>
                <li className="nk-menu-item">
                  <Link
                    onClick={closeSidebar}
                    to="/reviews"
                    className="nk-menu-link"
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-chat-fill" />
                    </span>
                    <span className="nk-menu-text">Reviews</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {sideBarOpen && (
        <div className="nk-sidebar-overlay" onClick={closeSidebar} />
      )}
    </>
  );
};

export default Sidebar;
