import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import Preloader from "../components/Preloader";

const Consultations = () => {
  const [quotes, setQuotes] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageToOpen, setMessageToOpen] = useState<any>({});

  useEffect(() => {
    const getQuotes = async () => {
      const { data } = await axios.get(
        "https://app.nelzmgt.com/api/consultation"
      );
      setQuotes(data);
    };

    getQuotes();
    setTimeout(() => {
      setPageLoading(false);
    }, 500);
  }, []);

  return (
    <>
      {pageLoading ? (
        <Preloader />
      ) : (
        <div className="nk-body bg-lighter npc-general has-sidebar ui-bordered ">
          <div className="nk-app-root">
            <div className="nk-main ">
              <Sidebar />
              <div className="nk-wrap ">
                <Header />
                <div className="nk-content ">
                  <div className="container-fluid">
                    <div className="nk-content-inner">
                      <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                          <div className="nk-block-between">
                            <div className="nk-block-head-content">
                              <h3 className="nk-block-title page-title">
                                Consultations
                              </h3>
                              <div className="nk-block-des text-soft">
                                <p>
                                  You have total {quotes?.length}{" "}
                                  {quotes?.length > 1
                                    ? "consultations"
                                    : "consultation"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-block">
                          <div className="nk-tb-list is-separate mb-3">
                            <div className="nk-tb-item nk-tb-head">
                              <div className="nk-tb-col">
                                <span className="sub-text">Name</span>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span className="sub-text">Email</span>
                              </div>
                              <div className="nk-tb-col tb-col-lg">
                                <span className="sub-text">Message</span>
                              </div>
                              <div className="nk-tb-col tb-col-lg">
                                <span className="sub-text">Date</span>
                              </div>

                              <div className="nk-tb-col nk-tb-col-tools"></div>
                            </div>
                            {quotes?.map((message: any) => (
                              <div className="nk-tb-item" key={message._id}>
                                <div className="nk-tb-col">
                                  <div className="user-card">
                                    {/* <div className="user-avatar bg-primary">
                                      <span>AB</span>
                                    </div> */}
                                    <div className="user-info">
                                      <span className="tb-lead">
                                        {message.name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span>{message.email}</span>
                                </div>
                                <div className="nk-tb-col tb-col-lg">
                                  <span>
                                    {message?.message?.substring(0, 35)}
                                    {message?.message?.length >= 35 && "..."}
                                  </span>
                                </div>
                                <div className="nk-tb-col tb-col-lg">
                                  <span>
                                    {new Date(
                                      message.createdAt
                                    ).toLocaleDateString()}
                                  </span>
                                </div>

                                <div className="nk-tb-col nk-tb-col-tools">
                                  <ul className="nk-tb-actions gx-1">
                                    <li>
                                      <div className="drodown">
                                        <a
                                          href="#/"
                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                          data-bs-toggle="dropdown"
                                        >
                                          <em className="icon ni ni-more-h" />
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          <ul className="link-list-opt no-bdr">
                                            <li>
                                              <a
                                                href="#/"
                                                onClick={() => {
                                                  setMessageModalOpen(true);
                                                  setMessageToOpen(message);
                                                }}
                                              >
                                                <em className="icon ni ni-eye" />
                                                <span>View Details</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ))}
                          </div>
                          {/* <div className="card">
                            <div className="card-inner">
                              <div className="nk-block-between-md g-3">
                                <div className="g">
                                  <ul className="pagination justify-content-center justify-content-md-start">
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        Prev
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        1
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        2
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <span className="page-link">
                                        <em className="icon ni ni-more-h" />
                                      </span>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        6
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        7
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        Next
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="g">
                                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                                    <div>Page</div>
                                    <div>
                                      <select
                                        className="form-select js-select2"
                                        data-search="on"
                                        data-dropdown="xs center"
                                      >
                                        <option value="page-1">1</option>
                                        <option value="page-2">2</option>
                                        <option value="page-4">4</option>
                                        <option value="page-5">5</option>
                                        <option value="page-6">6</option>
                                        <option value="page-7">7</option>
                                        <option value="page-8">8</option>
                                        <option value="page-9">9</option>
                                        <option value="page-10">10</option>
                                        <option value="page-11">11</option>
                                        <option value="page-12">12</option>
                                        <option value="page-13">13</option>
                                        <option value="page-14">14</option>
                                        <option value="page-15">15</option>
                                        <option value="page-16">16</option>
                                        <option value="page-17">17</option>
                                        <option value="page-18">18</option>
                                        <option value="page-19">19</option>
                                        <option value="page-20">20</option>
                                      </select>
                                    </div>
                                    <div>OF 102</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}

      {messageModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalDefault"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div>
                    <h5 className="modal-title">{messageToOpen?.name}</h5>
                    <p className="modal-title">{messageToOpen?.email}</p>
                    <p className="modal-title">{messageToOpen?.phone}</p>
                    <span style={{ fontSize: "12px" }}>
                      Service: {messageToOpen?.service}
                    </span>
                  </div>
                  <a
                    href="#/"
                    onClick={() => setMessageModalOpen(false)}
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross" />
                  </a>
                </div>
                <div className="modal-body">
                  <p>{messageToOpen?.message}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setMessageModalOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default Consultations;
