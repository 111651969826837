import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import Preloader from "../components/Preloader";

const MailingList = () => {
  const [list, setList] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    const getList = async () => {
      const { data } = await axios.get(
        "https://app.nelzmgt.com/api/mailing-list"
      );

      setList(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getList();
  }, []);

  return (
    <>
      {pageLoading ? (
        <Preloader />
      ) : (
        <div className="nk-body bg-lighter npc-general has-sidebar ui-bordered ">
          <div className="nk-app-root">
            <div className="nk-main ">
              <Sidebar />
              <div className="nk-wrap ">
                <Header />
                <div className="nk-content ">
                  <div className="container-fluid">
                    <div className="nk-content-inner">
                      <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                          <div className="nk-block-between">
                            <div className="nk-block-head-content">
                              <h3 className="nk-block-title page-title">
                                Mailing List
                              </h3>
                              <div className="nk-block-des text-soft">
                                <p>
                                  You have total {list?.length}{" "}
                                  {list?.length > 1 ? "emails" : "email"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-block">
                          <div className="nk-tb-list is-separate mb-3">
                            <div className="nk-tb-item nk-tb-head">
                              <div className="nk-tb-col">
                                <span className="sub-text">Email</span>
                              </div>

                              <div className="nk-tb-col tb-col-lg">
                                <span className="sub-text">Date</span>
                              </div>
                            </div>
                            {list?.map((message: any) => (
                              <div className="nk-tb-item" key={message._id}>
                                <div className="nk-tb-col">
                                  <div className="user-card">
                                    {/* <div className="user-avatar bg-primary">
                                      <span>AB</span>
                                    </div> */}
                                    <div className="user-info">
                                      <span className="tb-lead">
                                        {message.email}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="nk-tb-col tb-col-lg">
                                  <span>
                                    {new Date(
                                      message.createdAt
                                    ).toLocaleDateString()}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                          {/* <div className="card">
                            <div className="card-inner">
                              <div className="nk-block-between-md g-3">
                                <div className="g">
                                  <ul className="pagination justify-content-center justify-content-md-start">
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        Prev
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        1
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        2
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <span className="page-link">
                                        <em className="icon ni ni-more-h" />
                                      </span>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        6
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        7
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        Next
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="g">
                                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                                    <div>Page</div>
                                    <div>
                                      <select
                                        className="form-select js-select2"
                                        data-search="on"
                                        data-dropdown="xs center"
                                      >
                                        <option value="page-1">1</option>
                                        <option value="page-2">2</option>
                                        <option value="page-4">4</option>
                                        <option value="page-5">5</option>
                                        <option value="page-6">6</option>
                                        <option value="page-7">7</option>
                                        <option value="page-8">8</option>
                                        <option value="page-9">9</option>
                                        <option value="page-10">10</option>
                                        <option value="page-11">11</option>
                                        <option value="page-12">12</option>
                                        <option value="page-13">13</option>
                                        <option value="page-14">14</option>
                                        <option value="page-15">15</option>
                                        <option value="page-16">16</option>
                                        <option value="page-17">17</option>
                                        <option value="page-18">18</option>
                                        <option value="page-19">19</option>
                                        <option value="page-20">20</option>
                                      </select>
                                    </div>
                                    <div>OF 102</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MailingList;
