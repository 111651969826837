import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadingUser,
  noUser,
  loadUser,
  logoutUser,
} from "./store/redux/userRedux";
import axios from "axios";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Consultations from "./pages/Consultations";
import Messages from "./pages/Messages";
import MailingList from "./pages/Mailinglist";
import Reviews from "./pages/Reviews";

const App = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingUser());
    const token = localStorage.getItem("nelzadmin");

    const getUser = async () => {
      try {
        const { data } = await axios.get("https://app.nelzmgt.com/api/admin", {
          headers: { token: `Bearer ${token}` },
        });

        dispatch(loadUser(data));
      } catch (error: any) {
        if (error.response.data === "Token is not valid!") {
          localStorage.removeItem("nelzadmin");
          dispatch(logoutUser());
        }
      }
    };

    if (token) {
      getUser();
    } else {
      dispatch(noUser());
    }
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={user ? <Home /> : <Login />} />
      <Route
        path="/consultations"
        element={user ? <Consultations /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/dashboard"
        element={user ? <Home /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/mailing-list"
        element={user ? <MailingList /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/messages"
        element={user ? <Messages /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/reviews"
        element={user ? <Reviews /> : <Navigate replace to={"/"} />}
      />
    </Routes>
  );
};

export default App;
